<template>
  <modal-container
    @cancel="$emit('cancel')"
  >
    <p class="text-xl font-bold text-left text-primary">
      Share this item
    </p>
    <p class="pt-2 font-bold text-left">
      Enter multiple user's email addresss and the item will be sent to them!
    </p>
    <div class="flex flex-wrap w-full px-4 bg-backfill rounded-lg">
      <tokenized-input
        type="email"
        :resultingTokens.sync="emails"
      />
    </div>
    <div class="flex flex-row justify-end w-full pt-4">
      <button-large
        @click="$emit('cancel')"
        class="mr-3 w-28 phone:w-36"
        color="red"
      >
        Cancel
      </button-large>
      <button-large
        @click="addUsers"
        :disabled="emails.length === 0"
        class="w-28 phone:w-36"
        color="green"
      >
        Share
      </button-large>
    </div>

  </modal-container>
</template>

<script>
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import TokenizedInput from '@/components/shared/inputs/TokenizedInput.vue';
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';

export default {
  name: 'ProjectSettingsAddUsersModal',
  components: {
    ModalContainer,
    TokenizedInput,
    ButtonLarge,
  },
  data() {
    return {
      input: '',
      emails: [],
      permissions: 'basic',
    };
  },
  methods: {
    addUsers() {
      this.$emit('confirm', this.emails);
    },
  },
};
</script>

<style>

</style>
