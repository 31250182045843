<template>
  <a
    :href="serviceUrl"
    target="_blank"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 justify-between border-contrast
      rounded-lg cursor-pointer w-full bg-backfill
      service-component
      mb-2
    `"
  >
    <div class="flex flex-col items-start justify-center w-2/3 py-2 pl-4">
        <p class="font-bold text-left text-primary">{{name}}</p>
    </div>

    <img
        :src="image"
        alt="Project Image"
        class="self-center my-2 mr-4 rounded-full w-14 h-14"
    />

  </a>
</template>

<script>
export default {
  name: 'ServiceComponent',
  props: {
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    serviceUrl: {
      type: String,
      required: true,
    },
  },
};
</script>
