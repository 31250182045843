<template>
    <interior-page-content-container
        nav-selection=""
        @back-click="backNav"
        :show-back-button-top-left="true"
        :show-back-button-in-nav="true"
        :show-bottom-nav="true"
        :loading="loading"
        :modal-show="modal.shareItem"
        :notificationMessage.sync="notificationMessage"
        :showRightControls="true"
        @keyup="navigatePictures"
    >

        <template #modal>
          <item-share-modal
            @cancel="modal.shareItem = false"
            @confirm="shareItem"
          />
        </template>

        <template #right-action-buttons>
          <right-page-action-button
            v-for="rightActionButton in rightActionButtons"
            :key="rightActionButton.title"
            @click="$router.push(rightActionButton.route)"
            :active="false"
            :title="rightActionButton.title"
            :icon="rightActionButton.icon"
          />
          <right-page-action-button
            @click="modal.shareItem = true"
            :active="false"
            title="Share Item"
            icon="share"
          />
        </template>

        <template #title>
          <p>{{item.name}}</p>
        </template>

        <template #content>

          <div class="`
            flex flex-col items-stretch self-center h-full pt-10
            w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
            mb-10 pb-10
          `">

            <carousel-view
              :images="images"
              :selected.sync="imageSelected"
            />

            <!-- Item base info -->
            <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
              <text-display-component
                label="Category"
                :value="item.category"
              />
              <text-display-component
                v-if="item.description.trim().length > 0"
                label="Description"
                :value="item.description"
              />
            </div>

            <!-- Memory info -->
            <div v-if="item.category === 'Memory'" class="w-full">
              <p class="ml-5 text-lg font-extrabold text-left text-content">Memory Notes</p>
              <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
                <text-display-component
                  v-for="question in memoryQuestions"
                  :key="question"
                  v-show="item.memory_info[question].trim().length > 0"
                  :label="question"
                  :value="item.memory_info[question].trim()"
                />
              </div>
            </div>

            <!-- Services -->
            <div v-if="services">
              <p class="mb-5 text-lg font-bold text-content">
                {{offeredServiceTitle}}
              </p>
              <service-component
                v-for="service in services"
                :key="service.name"
                :name='service.name'
                :image="service.image"
                :serviceUrl="service.url"
              />
            </div>

          </div>
        </template>

        <template #bottom-nav-center>
          <shareIcon
            @click="modal.shareItem = true"
            class="cursor-pointer share"
            :size='37'
          />
        </template>

        <template #bottom-nav-right>
          <settings
            v-if="projectPermissions !== 'basic'"
            @click="settings"
            class="cursor-pointer settings"
            :size='37'
          />
        </template>

    </interior-page-content-container>
</template>

<script>
import Settings from 'vue-material-design-icons/Cog.vue';
import ShareIcon from 'vue-material-design-icons/Share.vue';

import { mapItemFields } from '@/store/mappers';
import { ITEM_GET, ITEM_SHARE } from '@/store/actions';
import { PROJECT_PERMISSIONS_CURRENT } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ServiceComponent from '@/components/projects/ServiceComponent.vue';
import CarouselView from '@/components/shared/general/CarouselView.vue';
import RightPageActionButton from '@/components/shared/inputs/RightPageActionButton.vue';
import ItemShareModal from '@/components/projects/ItemShareModal.vue';
import TextDisplayComponent from '@/components/shared/general/TextDisplayComponent.vue';
import * as ItemServices from '../../assets/json/item-services.json';
import * as memoryQuestions from '../../assets/json/memoryQuestions.json';

export default {
  name: 'Item',
  components: {
    InteriorPageContentContainer,
    RightPageActionButton,
    TextDisplayComponent,
    ServiceComponent,
    CarouselView,
    Settings,
    ShareIcon,
    ItemShareModal,
  },
  data() {
    return {
      loading: true,
      imageSelected: 0,
      showNotification: false,
      notificationMessage: '',
      modal: {
        shareItem: false,
      },
    };
  },
  computed: {
    ...mapItemFields(['item', 'items']),
    projectPermissions() {
      return this.$store.getters[PROJECT_PERMISSIONS_CURRENT];
    },
    offeredServiceTitle() {
      if (this.item.category === 'Moving') return 'Moving companies for you:';
      if (this.item.category === 'Storage') return 'Storage companies near you:';
      if (this.item.category === 'Junk') return 'Junk management near you:';
      if (this.item.category === 'Donation') return 'Donation locations near you:';
      return '';
    },
    services() {
      return ItemServices.services[this.item.category];
    },
    memoryQuestions() {
      return memoryQuestions.memoryQuestions;
    },
    images() {
      const images = [];
      if (this.item.picture) images.push(this.item.picture.url);
      if (this.item.picture_2) images.push(this.item.picture_2.url);
      if (this.item.picture_3) images.push(this.item.picture_3.url);
      return images;
    },
    rightActionButtons() {
      if (this.projectPermissions === 'basic') return [];
      return [
        {
          title: 'Settings',
          icon: 'settings',
          route: {
            name: 'ItemSettings',
            params: {
              project_id: this.$route.params.project_id,
              room_id: this.$route.params.room_id,
              item_id: this.$route.params.item_id,
            },
          },
        },
      ];
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'ItemCreate') {
      next({
        name: 'Room',
        params: {
          projectId: this.$route.params.project_id,
          roomId: this.$route.params.room_id,
        },
      });
    }
    next();
  },
  async created() {
    this.loading = !this.items[this.$route.params.item_id];
    this.item = this.items[this.$route.params.item_id] || {};
    window.addEventListener('keyup', this.navigatePictures);
    this.$store.dispatch(ITEM_GET, {
      projectId: this.$route.params.project_id,
      roomId: this.$route.params.room_id,
      itemId: this.$route.params.item_id,
    })
      .catch(() => this.$router.push({
        name: 'SpecificPageNotFound',
        params: { page: 'item' },
      }))
      .finally(() => { this.loading = false; });
  },
  destroyed() {
    window.removeEventListener('keyup', this.navigatePictures);
  },
  methods: {
    navigatePictures(event) {
      if (event.key === 'ArrowRight') this.imageSelected += 1;
      if (event.key === 'ArrowLeft') this.imageSelected -= 1;
    },
    settings() {
      this.$router.push({
        name: 'ItemSettings',
        params: {
          project_id: this.$route.params.project_id,
          room_id: this.$route.params.room_id,
          item_id: this.$route.params.item_id,
        },
      });
    },
    backNav() {
      this.$router.go(-1);
    },
    shareItem(emails) {
      this.modal.shareItem = false;
      console.log(emails);
      this.$store.dispatch(ITEM_SHARE, {
        projectId: this.$route.params.project_id,
        roomId: this.$route.params.room_id,
        itemId: this.$route.params.item_id,
        emails,
      });
      this.notificationMessage = 'Items have been shared!';
      // TODO add in a notification telling the user the item has been shared!
    },
  },
};
</script>
